/* A module containing functions for populating the content
of pop-up info boxes on the map. Functions that aren't exported from this module start with an underscore,
like this: _doStuff, and are at the top of the file. */

import mapboxgl from 'mapbox-gl';
import * as constants from './constants.js';
import * as functions from './functions.js';
import * as layers from './layers.js';
import map from './map.js';

// build our popup API requests and show a message if we can't
if (!('EPRI_LOAD_SHAPES_API_KEY' in process.env)) {
    console.error(
        'Load shapes API key is missing from the environment. Cannot fetch popup data.'
    );
}

const APIRequestHeaders = new Headers();
APIRequestHeaders.set(
    'Ocp-Apim-Subscription-Key',
    process.env.EPRI_LOAD_SHAPES_API_KEY
);

const APIRequestOptions = { headers: APIRequestHeaders };

if (!('EPRI_LOAD_SHAPES_API_URL' in process.env)) {
    console.error(
        'Load shapes API url is missing from the environment. Cannot fetch popup data.'
    );
}

// Warm up the DNS cache by hitting the load shapes API once on page load. These are
// just arbitrary values.
fetch(
    `${process.env.EPRI_LOAD_SHAPES_API_URL}/8828d542e9fffff/2030`,
    APIRequestOptions
)
    .then((response) => {
        if (response.status == 200) {
        } else {
            console.error(
                `Error reaching load shape API endpoint: ${response.status} - ${response.statusText}`
            );
        }
    })
    .catch((error) => {
        console.error('Error reaching load shape API endpoint:', error);
    });

// Function to get load shape data
function _fetchLoadShape(hexid) {
    let year = 100;
    if (!functions.fullElectrificationChecked()) {
        year = functions.getCurrentYear();
    }
    if (!('EPRI_LOAD_SHAPES_API_URL' in process.env)) {
        console.error(
            'Load shapes API url is missing from the environment. Cannot fetch popup data.'
        );
        return;
    }
    return fetch(
        `${process.env.EPRI_LOAD_SHAPES_API_URL}/${hexid}/${year}`,
        APIRequestOptions
    )
        .then((response) => {
            if (response.status == 200) {
                return response.json();
            } else {
                console.error(
                    `Error fetching load shape API data: ${response.status} - ${response.statusText}`
                );
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.error(error);
        });
}

// round a value to dp decimal places
// or if dp is left out then do 3 decimal places for values < 1, and none for values >= 1
function _round(num, dp) {
    let multiplier = 1;
    if (dp != undefined) {
        multiplier = Math.pow(10, dp);
    } else if (num < 1) {
        multiplier = 1000;
    }
    return (
        Math.round((num + Number.EPSILON) * multiplier) / multiplier
    ).toLocaleString('en-US');
}

// A helper for the popup creation functions. Many of them use a background color
// for the popup title that matches the data value of the point generating the popup.
// If the color is dark enough, it needs to have the title and close button in
// white.
// bgcolor: the background color in question
// popup: the popup we are manipulating
// returns; the background color, as it needs to appear in the popup CSS
// side effect: styles the popup close button white
function _whiteTextForDarkPopups(bgColor, popup) {
    if (constants.darkCategoryColors.includes(bgColor)) {
        bgColor += '; color: #FFF;';

        // if the popup title is dark, make the popup's close button white when it exists.
        popup.once('open', function () {
            popup
                .getElement()
                .getElementsByClassName('mapboxgl-popup-close-button')[0]
                .classList.add('white');
        });
    }
    return bgColor;
}

// A helper for the hex popups. Given the value of the total energy in a hex,
// tell me what color it is in the map. Necessary because the data
// isn't plumbed through in mapbox's api.
// dataValue: the total energy in a hex, as an integer.
function _getHexColorFromDataValue(dataValue) {
    const _baseMapType = functions.getBaseMapType();

    let _categoryBreaks, _categoryColors;

    if (_baseMapType == 'power') {
        _categoryBreaks = constants.powerCategoryBreaks;
        _categoryColors = constants.powerCategoryColors;
    } else if (_baseMapType == 'energy') {
        _categoryBreaks = constants.energyCategoryBreaks;
        _categoryColors = constants.energyCategoryColors;
    }
    const breaks =
        _categoryBreaks[functions.colorRampType][functions.hexResFromZoom()];
    let hex = _categoryColors[functions.colorRampType].at(-1);

    for (let i = 0; i < breaks.length; i++) {
        if (dataValue < breaks[i]) {
            hex = _categoryColors[functions.colorRampType][i];
            return hex;
        }
    }
    return hex;
}

// A helper for the hosting capacity popups. Given the load capacity of a line,
// tell me what color it is on the map.
// dataValue: the load capacity of the line, as an integer
function _getLineColorFromDataValue(dataValue) {
    let lineColor = constants.hostingCapacityColors.at(-1);
    for (let i = 0; i < constants.hostingCapacityBreaks.length; i++) {
        if (dataValue < constants.hostingCapacityBreaks[i]) {
            lineColor = constants.hostingCapacityColors[i];
            return lineColor;
        }
    }
    return lineColor;
}

// it's our date formatting object, so we can have one for the whole page, instead of one for each popup.
const dateFormatter = Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
});

// A helper function to sort the utilities in a popup by area, ascending.
function _compareUtilities(a, b) {
    return a.properties.area - b.properties.area;
}

// A helper function to build the html content of the utility popup
function _buildUtilityContent(data) {
    data.sort(_compareUtilities);

    const utilities = data.map((x) => x.properties.COMPANY ?? 'Name Unknown');
    // Most of the time, there is just one possible utility company for a point
    let companyContent = `<span class='attribute'>${utilities[0]}</span>`;
    let owner = 'Utility:';
    if (utilities.length > 1) {
        // .. but only most of the time.
        owner = 'Possible utilities, sorted by area (largest first):';
        companyContent = `</div><div class="popup-datum popup-datum--utilitylist"><ul class="utility-companies">`;
        for (c in utilities) {
            companyContent += `<li class='attribute'>${utilities[c]}</li>`;
        }
        companyContent += '</ul></div>';
    }
    return `<div class="popup-datum">
            <span class='varname'>${owner}</span>
                ${companyContent}
          </div>`;
}

// A helper function to get the utility data from any layer's popup handler.
function _getUtilityDataForPoint(point) {
    const data = map.queryRenderedFeatures(point, {
        layers: [layers.utilityServiceAreasLayer.id],
    });
    if (data && data.length > 0) {
        return _buildUtilityContent(data);
    } else {
        return '';
    }
}

// A helper function to get the hex data from another layer's popup handler.
function _getHexDataForPoint(point) {
    const data = map.queryRenderedFeatures(point, {
        layers: [layers.dynamicTilesLayer.id],
    });
    if (data && data[0] && data[0].properties) {
        return data[0].properties;
    } else {
        return null;
    }
}

// For hex popup summaries, we want to display the maximum value
// for the 24 hours covered by each load shape json array, formatted
// in a nice human-readable package.
function _getMaxFromLoadShapeData(data, attribute) {
    return _round(
        data.reduce(
            (max, point) => Math.max(max, point[attribute]),
            data[0][attribute]
        )
    );
}

// A helper function to build the hex popup content, so it can be called
// from other popup handlers if we want.
function _buildHexPopupContent(data, utilityContent) {
    let totalEnergy;
    let unmanagedPower;
    let managedPower;
    let filterContent = '';
    const longHaulExcluded = !functions.longHaulIncluded();
    if (longHaulExcluded) {
        totalEnergy = _round(data.energy_no_lh);
        unmanagedPower = _round(data.power_unmanaged_no_lh);
        managedPower = _round(data.power_managed_no_lh);
        filterContent = `<div class="popup-datum">Long-Haul Trucks Excluded</div>`;
    } else {
        totalEnergy = _round(data.energy_total);
        unmanagedPower = _round(data.power_unmanaged_total);
        managedPower = _round(data.power_managed_total);
    }
    const level = functions.hexResFromZoom();

    // so I don't have to write the whole thing out twice
    function _makeTable(
        energyLoadShapeContent = '',
        powerLoadShapeContent = ''
    ) {
        return `
                ${filterContent}
                ${utilityContent}
                  <table class="popup-table popup-table--energy">
                    <thead class="popup-table__header">
                        <tr>
                            <th scope="column">Energy</th>
                            <th scope="column">(MWh / Day)</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${energyLoadShapeContent}
                        <tr>
                            <th scope="row">Total</td>
                            <td>${totalEnergy}</td>
                        </tr>
                    </tbody>
                  </table>
                  <table class="popup-table popup-table--power">
                    <thead class="popup-table__header">
                        <tr>
                            <th scope="column">Peak Power (MW)</th>
                            <th scope="column">Unmanaged</th>
                            <th scope="column">Managed</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${powerLoadShapeContent}
                        <tr>
                            <th scope="row">Total</th>
                            <td>${unmanagedPower}</td>
                            <td>${managedPower}</td>
                        </tr>
                    </tbody>
                  </table>
                  <i>Numbers are rounded for display purposes.</i>
                  <div class="popup-datum popup-datum--hexinfo">
                    <span class='varname'>Hex&nbsp;Level&nbsp;${level}</span><span class='attribute'>ID:&nbsp;${data.hexid}</span>
                  </div>
              </div>`;
    }

    return _fetchLoadShape(data.hexid, data.year).then((loadShapeData) => {
        if (!loadShapeData) {
            // something went wrong with the API call; show what we can and bail
            return _makeTable();
        }

        // light duty power
        const max_unmanaged_ld = _getMaxFromLoadShapeData(
            loadShapeData,
            'unmanaged_ld'
        );
        const max_managed_ld = _getMaxFromLoadShapeData(
            loadShapeData,
            'managed_ld'
        );

        // light duty energy
        const energy_unmanaged_ld = _round(
            loadShapeData.reduce((sum, point) => sum + point.unmanaged_ld, 0)
        );

        let max_unmanaged_mdhd;
        let max_managed_mdhd;
        let energy_unmanaged_mdhd;

        if (longHaulExcluded) {
            max_unmanaged_mdhd = _getMaxFromLoadShapeData(
                loadShapeData,
                'unmanaged_mdhd'
            );
            max_managed_mdhd = _getMaxFromLoadShapeData(
                loadShapeData,
                'managed_mdhd'
            );
            energy_unmanaged_mdhd = _round(
                loadShapeData.reduce(
                    (sum, point) => sum + point.unmanaged_mdhd,
                    0
                )
            );
        } else {
            // include long haul truck numbers
            max_unmanaged_mdhd = _round(
                loadShapeData.reduce(
                    (max, point) =>
                        Math.max(
                            max,
                            point.unmanaged_mdhd + point.unmanaged_lh
                        ),
                    loadShapeData[0].unmanaged_mdhd +
                        loadShapeData[0].unmanaged_lh
                )
            );
            max_managed_mdhd = _round(
                loadShapeData.reduce(
                    (max, point) =>
                        Math.max(max, point.managed_mdhd + point.managed_lh),
                    loadShapeData[0].managed_mdhd + loadShapeData[0].managed_lh
                )
            );
            energy_unmanaged_mdhd = _round(
                loadShapeData.reduce(
                    (sum, point) =>
                        sum + point.unmanaged_mdhd + point.unmanaged_lh,
                    0
                )
            );
        }

        const energyContent = `                <tr>
                    <th scope="row">Light Duty</th>
                    <td>${energy_unmanaged_ld}</td>
                </tr>
                <tr>
                    <th scope="row">Medium / Heavy Duty</th>
                    <td>${energy_unmanaged_mdhd}</td>

                </tr>`;

        const powerContent = `                <tr>
                    <th scope="row">Light Duty</th>
                    <td>${max_unmanaged_ld}</td>
                    <td>${max_managed_ld}</td>
                </tr>
                <tr>
                    <th scope="row">Medium / Heavy Duty</th>
                    <td>${max_unmanaged_mdhd}</td>
                    <td>${max_managed_mdhd}</td>
                </tr>`;

        return _makeTable(energyContent, powerContent);
    });
}

// A helper function that gets appropriately formatted hex popup content
// to include in other layers' popups if the map is zoomed in below hex 8
// and if that data exists.
function _getHexPopupContentForPoint(point, showUtility = true) {
    const contentDiv = document.getElementById('hex-content');
    if (functions.hexResFromZoom() >= 8) {
        const hexData = _getHexDataForPoint(point);
        let utilities = '';
        if (showUtility) {
            utilities = _getUtilityDataForPoint(point);
        }

        if (hexData) {
            _buildHexPopupContent(hexData, utilities).then((content) => {
                // There's a chance that it got dismissed before this code can run
                if (contentDiv) {
                    contentDiv.innerHTML = `
                        <div class='popup-secondary-title'>Hexagon Info</div>
                        ${content}`;
                }
            });
            return;
        }
    }
    // If we aren't below hex 8 and we don't have hex data, remove the placeholder div
    contentDiv.remove();
}

// Given a popup for the transportation layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillTransportationPopup(event, popup) {
    const data = event.features[0].properties;
    const tract_name = data.tract_name ?? 'Unknown Census Tract';

    const html = `<div class="popup-title">Transportation Disadvantage</div>
        <div class='popup-body'>
          <div class="popup-datum">
            <span class='attribute'>${tract_name}</span>
          </div>
          <p>People who live here have worse access to transportation than people in 90% of census tracts in the United States.</p>
          <div id="hex-content" class="hex-content-container"></div>
      </div>`;

    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Given a popup for the pm25 layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillPm25Popup(event, popup) {
    const data = event.features[0].properties;
    const bgColor = _whiteTextForDarkPopups('#FBB800', popup);

    const tract_name = data.tract_name ?? 'Unknown Census Tract';
    let pm25 = 'Unknown';
    if (data.pm25) {
        pm25 = _round(parseFloat(data.pm25, 10), 2);
    }

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Air Quality by Census Tract</div>
        <div class='popup-body'>
          <div class="popup-datum">
            <span class='attribute'>${tract_name}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>PM2.5 Air Quality:</span><span class='attribute'>${pm25}</span>
          </div>
          <i>PM2.5 is in micrograms per cubic meter</i>
          <div id="hex-content" class="hex-content-container"></div>
      </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Given a popup for the justice40 layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillJustice40Popup(event, popup) {
    const data = event.features[0].properties;
    const bgColor = _whiteTextForDarkPopups(constants.softBlack, popup);
    const tract_name = data.tract_name ?? '';
    let pm25 = 'Unknown';
    if (data.pm25) {
        pm25 = _round(parseFloat(data.pm25, 10), 2);
    }

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Justice40 Tract</div>
        <div class='popup-body'>
          <div class="popup-datum">
            <span class='attribute'>${tract_name}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>PM2.5 Air Quality:</span><span class='attribute'>${pm25}</span>
          </div>
          <i>PM2.5 is in &#956;g / cubic meter</i>
          <div id="hex-content" class="hex-content-container"></div>
      </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Given a popup for the truck stops layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillTruckStopsPopup(event, popup) {
    const data = event.features[0].properties;
    const bgColor = _whiteTextForDarkPopups(constants.softBlack, popup);
    const name = data.NHS_Rest_S ?? 'Name Unknown';
    let parking_count = 0;
    if (data.prkngcount) {
        parking_count = parseInt(data.prkngcount, 10);
    }
    const road_name = data.Highway_Ro ?? 'Unknown';
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Truck Stop</div>
        <div class='popup-body'>
          <div class="popup-datum">
            <span class='attribute'>${name}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Parking Spots:</span><span class='attribute'>${parking_count}</span>
          </div>
          <div class="popup-datum">
          <span class='varname'>Road:</span><span class='attribute'>${road_name}</span>
          </div>
          <div id="hex-content" class="hex-content-container"></div>
        </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Given a popup for the utility service areas layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillUtilityServiceAreasPopup(event, popup) {
    const bgColor = _whiteTextForDarkPopups(constants.softBlack, popup);
    const utilityContent = _buildUtilityContent(event.features);

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Utility Service Area(s)</div>
        <div class='popup-body'>
          ${utilityContent}
          <div id="hex-content"></div>
      </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point, false); // do not show utility data in this hex content
}

// Given a popup for the EV charging stations layer, use the data at that point in it
// to fill the popup with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillEVPopup(event, popup) {
    const data = event.features[0].properties;
    let confirmed_date = 'Unknown';
    if (data.date_last_confirmed) {
        confirmed_date = dateFormatter.format(
            Date.parse(data.date_last_confirmed)
        );
    }
    let lastRetrieved = 'Unknown';
    if (data.last_updated) {
        lastRetrieved = dateFormatter.format(Date.parse(data.last_updated));
    }
    let level2_ports = 'Unknown Number';
    if (data.level2_ports !== undefined) {
        level2_ports = parseInt(data.level2_ports, 10);
    }
    let dc_fast_ports = 'Unknown Number';
    if (data.dc_fast_ports !== undefined) {
        dc_fast_ports = parseInt(data.dc_fast_ports, 10);
    }

    let bgColor = constants.softBlack;
    if (dc_fast_ports > 0) {
        bgColor = constants.fastPortColor;
    }
    bgColor = _whiteTextForDarkPopups(bgColor, popup);

    const station_name = data.station_name ?? 'Unknown Name';
    const network = data.network ?? 'Unknown Charging Network';
    const connector_types = data.connector_types ?? 'Unknown';

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>EV Charging Station</div>
        <div class='popup-body'>
          <div class="popup-datum">
            <span class='attribute'>${station_name}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>${network}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Level 2 Ports:</span> <span class='attribute'>${level2_ports}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Fast Ports:</span> <span class='attribute'>${dc_fast_ports}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Connector Types:</span> <span class='attribute'>${connector_types}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Confirmed by Provider:</span> <span class='attribute'>${confirmed_date}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Retrieved by EPRI:</span> <span class='attribute'>${lastRetrieved}</span>
          </div>
          <div class="popup-datum">
            <span class='attribute'>
              <a class='popup-link' target='_blank' rel='noopener' href='https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/Alternate_Fuel/FeatureServer/0/query?outFields=*&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&returnZ=false&returnM=false&returnExceededLimitFeatures=true&f=html&where=station_id%3D${data.station_id}'>
                View Source Data
              </a>
            </span>
          </div>
          <div id="hex-content" class="hex-content-container"></div>
        </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Given a popup for the hosting capacity layer, use the data at that point in it
// to fill the popup with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillHostingCapacityPopup(event, popup) {
    const data = event.features[0].properties;

    let load_capacity = 'Unknown';
    if (data.load_capacity !== undefined) {
        load_capacity = parseFloat(data.load_capacity, 10);
    }
    let bgColor = _getLineColorFromDataValue(load_capacity);
    bgColor = _whiteTextForDarkPopups(bgColor, popup);
    let lastRetrieved = 'Unknown';
    if (data.last_retrieved) {
        lastRetrieved = dateFormatter.format(Date.parse(data.last_retrieved));
    }
    const lastUpdated = data.source_last_updated ?? 'Unknown';
    const source = data.source ?? 'Unknown';
    const line_name = data.line_name ?? 'Unknown';
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Line Capacity</div>
        <div class='popup-body'>
          <div class="popup-datum">
          ${line_name}
          </div>
          <div class="popup-datum">
            <span class='varname'>Load Capacity (MW):</span><span class='attribute'>${load_capacity}</span>
          </div>
          <div class='popup-datum hosting-source'>
            <span class='varname'>Data&nbsp;Source:</span> <span class='attribute'>${source}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Updated by Utility:</span> <span class='attribute'>${lastUpdated}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Retrieved by EPRI:</span> <span class='attribute'>${lastRetrieved}</span>
          </div>
          <div id="hex-content" class="hex-content-container"></div>
        </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Given a popup for the hex / main data layers, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillHexPopup(event, popup) {
    const data = event.features[0].properties;
    const utilities = _getUtilityDataForPoint(event.point);

    const hexValue = data[functions.getHexAttributeFromMapState()];

    let bgColor = _getHexColorFromDataValue(hexValue);
    bgColor = _whiteTextForDarkPopups(bgColor, popup);

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Totals for this Hexagon</div>
        <div class='popup-body'><div id='hex-popup-content'></div></div>`;

    popup.setHTML(html);
    popup.addTo(map);

    _buildHexPopupContent(data, utilities).then((hexContent) => {
        const contentDiv = document.getElementById('hex-popup-content');
        if (contentDiv) {
            // it might have been dismissed before we get here
            contentDiv.innerHTML = hexContent;
        }
    });
}

// Given a popup for the hex / main data layers, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillMultiFamilyPopups(event, popup) {
    const data = event.features[0].properties;
    const bgColor = _whiteTextForDarkPopups('#FBB800', popup);
    const tract_name = data.tract_name ?? 'Unknown Census Tract';
    let mfd_percent = 'Unknown';
    if (data.mfd_percent) {
        mfd_percent = _round(data.mfd_percent * 100);
    }
    let population = 'Unknown';
    if (data.total_pop) {
        population = data.total_pop;
    }
    let mfd_number = 'Unknown';
    if (data.multi_family) {
        mfd_number = data.multi_family;
    }
    const html = `<div class='popup-title' style='background-color: ${bgColor}'>MultiFamily Buildings</div>
        <div class='popup-body'>
          <div class="popup-datum">
            <span class='attribute'>${tract_name}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Households in Multifamily Buildings:</span><span class='attribute'>${mfd_number} (${mfd_percent}%)</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Residential Buildings in this Tract:</span></span class='attribute'>${population}</span>
          </div>
          <div id="hex-content" class="hex-content-container"></div>
        </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Given a popup for the cost burdened households layer, use the data at that point on the
// map to fill it with HTML content.
// data: the data from the mapbox feature that just got clicked on
// popup: a mapboxgl.Popup object
function fillCostBurdenedHouseholdsPopup(event, popup) {
    const data = event.features[0].properties;
    const bgColor = _whiteTextForDarkPopups('#FBB800', popup);

    const tract_name = data.tract_name ?? 'Unknown Census Tract';
    let percentage = 'Unknown';
    if (data.cost_burdened_percent) {
        percentage = _round(data.cost_burdened_percent * 100);
    }
    let total = 'Unknown';
    if (data.total_households) {
        total = data.total_households;
    }
    let n_cost_burdened = 'Unknown';
    if (data.n_cost_burdened) {
        n_cost_burdened = data.n_cost_burdened;
    }

    const html = `<div class='popup-title' style='background-color: ${bgColor}'>Cost Burdened Households</div>
        <div class='popup-body'>
          <div class="popup-datum">
            <span class='attribute'>${tract_name}</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Cost Burdened Households: </span><span class='attribute'>${n_cost_burdened} (${percentage}%)</span>
          </div>
          <div class="popup-datum">
            <span class='varname'>Total Households in This Area: </span><span class='attribute'>${total}</span>
          </div>
          <p>Households that are both earning less than 80% of Housing and Urban Development’s Area Median Family Income and are spending more than 30% of their income on housing costs.</p>
          <div id="hex-content" class="hex-content-container"></div>
      </div>`;
    popup.setHTML(html);
    popup.addTo(map);
    _getHexPopupContentForPoint(event.point);
}

// Add an event handler for popups to any layer.
// layer: the Mapbox layer to add the handler to
// contentFunction: the function that puts content in the popup needs to take two arguments:
//    - data: the data from the mapbox feature that just got clicked on
//    - popup: a mapboxgl.Popup object
// See fillHexPopup(data, popup) for an example
function addPopupHandler(layer, contentFunction) {
    map.on('click', layer, function (e) {
        if (
            layer == layers.utilityServiceAreasLayer.id &&
            map.getPaintProperty(layer, 'fill-opacity') == 0
        ) {
            // don't show popups for our invisible layer
            return;
        }
        functions.clearpopups();
        let popup = new mapboxgl.Popup({ maxWidth: '300px' }).setLngLat(
            e.lngLat
        );
        contentFunction(e, popup);
    });

    // Change the cursor to a pointer when the mouse is over the data layer.
    map.on('mouseenter', layer, function () {
        map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', layer, function () {
        map.getCanvas().style.cursor = '';
    });
}

export {
    addPopupHandler,
    fillCostBurdenedHouseholdsPopup,
    fillEVPopup,
    fillHexPopup,
    fillHostingCapacityPopup,
    fillJustice40Popup,
    fillMultiFamilyPopups,
    fillPm25Popup,
    fillTransportationPopup,
    fillTruckStopsPopup,
    fillUtilityServiceAreasPopup,
};
